import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { connect } from 'react-redux'
import Products from "../components/ProductsList"
import PageTitle from '../components/PageTitle'
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { color, media } from "../components/Styles"
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next"
import Marcas from "../components/Marcas"

const CategoryPage = ({ products, pageContext }) => {
    const { name, slug, categories, parent } = pageContext
    const { t } = useTranslation()


    return (
        <Layout>
            <Seo title={name} />
            <PageTitle
                title={name}
                subtitle={t("products", { returnObjects: true }).product.option}
            />
            <BreadStyled>
                <div className="breadtxt">
                    <Breadcrumbs aria-label="breadcrumb" style={{ fontFamily: "montserrat", textTransform: "uppercase" }}>
                        <Link underline="hover" color="inherit" href="/#produto">
                            {t("products", { returnObjects: true }).product.title}
                        </Link>
                        {parent.length > 0 &&
                            <Link
                                underline="hover"
                                color="inherit"
                                href={parent[0].link}
                            >
                                {parent[0].name}
                            </Link>
                        }
                        <Link
                            underline="hover"
                            aria-current="page"
                            style={{ color: "#F2AF00", fontWeight: "600", textDecoration: "underline" }}
                        >
                            {name}
                        </Link>
                    </Breadcrumbs>
                </div>
            </BreadStyled>

            <CategoryStyled>
                <div className="filter">
                    <div className="filteroption">

                        <Accordion>
                            <Accordion.Item>
                                <Accordion.Header><h3 style={{ textTransform: "uppercase", fontFamily: "montserrat" }}>{name}</h3></Accordion.Header>
                                <Accordion.Body>
                                    {categories.map((categories, i) => {
                                        return (
                                            <div key={i}>
                                                <Link to={categories.link}>
                                                    <p className="optiontxt">{categories.name}</p>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>
                </div>
            </CategoryStyled>
            <Products
                products={products}
                category={slug}
            />
            <Marcas data={t("home", { returnObjects: true })} />
        </Layout >
    )
}

export const query = graphql`
query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "products"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default connect(state => ({
    products: state.app.products
}), null)(CategoryPage)

const BreadStyled = styled.div`

        .breadtxt {
            display: flex;
            justify-content: center;
            margin-top: 2%;

            ${media.s`
            margin-top: 7%;
            `}
        }
`

const CategoryStyled = styled.div`

    .filter {
        padding: clamp(30px,15vw,0px) 5%;
        

        .filtertxt {
            display: flex;
            justify-content: center;
            font-weight: 800;
            text-transform: uppercase;
            color: #0000004f;
        }

        .filteroption {
            display: flex;
            justify-content: center;
            gap: 4%;
            padding: 3rem 1%;
            cursor: pointer;
            border-bottom: 2px solid ${color.yellow};
            flex-wrap: wrap;
            flex-direction: column;

            .optiontxt {
                border: 1px solid black;
                padding: 7px 30px;
                text-transform: uppercase;
                font-weight: 700;
                text-align: center;
                display: flex;
                gap: 5%;
                justify-content: center;


                : hover {
                    background-color: ${color.yellow};
                    color: ${color.white};
                    border: ${color.yellow};
                    transition: all .35s;
                    
                }
            }

            .optiontxt {
                border: none;
                padding: 7px 30px;
                text-transform: uppercase;
                font-weight: 700;
                text-align: center;
                display: flex;
                gap: 5%;

                : hover {
                    background-color: ${color.yellow};
                    color: ${color.white};
                    border: ${color.yellow};
                    transition: all .35s;
                    
                }
            }

            .option-check {
                padding: 7px 30px;
                text-transform: uppercase;
                font-weight: 700;
                text-align: center;
                display: flex;
                gap: 5%;
                background-color: ${color.yellow};
                color: ${color.white}

                
            }
        }

    }
`