import * as React from "react"
import styled from 'styled-components'
import { color, media } from './Styles'
import { Image } from './Images'

const PageTitle = ({ title, subtitle }) => {
    return(
        <TitleStyled>
            <div className='background'>
                <Image src='header-footer/bg.png' alt='Enomac'/>
            </div>
            <div className='wrapper'>
                <div className='wrapper-title'>
                    <h3>{title}</h3>
                </div> 
                <p className='subtitle'>
                    {subtitle}
                </p>          
            </div>
        </TitleStyled>
    )
}

export default PageTitle

const TitleStyled = styled.div`
    position:relative;
    overflow:hidden;

    .background{
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: -150%;

        ${media.m`
            left: -25%;
        `}

        .image{
            width: 100%;
            height: 250%;

            ${media.m`
                width: 150%;
            `}
        }
    }
    
    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding:clamp(30px,15vw,80px) 5%;
        color:${color.white};
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap:1rem;

        ${media.xl`
            padding:clamp(30px,15vw,50px) 5%;
        `}

        ${media.m`
            padding: clamp(35px,15vw,35px) 5%;
            gap:1rem;
        `}

        .wrapper-title{
            width:100%;
            max-width: 500px;
            margin: 0 auto;
            border-bottom: 3px solid ${color.yellow};
            padding-bottom:1rem;

            h3{
                font-weight:600;
                text-transform: uppercase;
                font-size: 1.5rem;
                letter-spacing: 0.3rem;
                text-align: center;
                line-height: 2.5rem;
            }
        }

        .subtitle{
            text-align: center;
        }
    }
`